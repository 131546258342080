// src/pages/404.js
import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
//import SEO from "../components/seo"
import "../styles/404.css"

export default function NotFound() {
    return (
        <Layout>
            <div className="not-found-message">
                <h1>404 Not Found</h1>
                <Link to="/">トップページへ</Link>
            </div>
        </Layout>
    )
}
